<template>
  <div>
    <!-- <TheHeader /> -->

    <NuxtPage />
  </div>
</template>

<script>
export default {
  name: "Auth",
  layout: "pages",
  methods: {
    goto() {
      navigateTo("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
